a {
  word-break: break-all !important;
}

.datenText {
  width: 50%;
  padding-right: 70px;
  font-family: bebas-neue-pro-semiexpanded, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: white;
  padding-left: 130px;
  font-size: 18px;
}

.datenText h2 {
  font-weight: 600;
}

h3 {
  margin-top: 20px;
  font-weight: 600;
}

.mainContainerAgb {
  padding-top: 158px;
}

.leftContainerRecht {
  width: 50%;
  background-color: #d80d41;
}

.leftContainerAgb {
  width: 50%;
  background-color: #d80d41;
}

.datenText h3,
.datenText h2 {
  margin-top: 60px;
}

.agb {
  padding-left: 130px;
  width: 50%;
  padding-right: 80px;
  color: white;
  font-family: bebas-neue-pro-semiexpanded, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 50px;
  margin-bottom: 0;
}

.imp {
  font-family: bebas-neue-pro-semiexpanded, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;

  color: white;
  padding-left: 130px;
}

.datenWhite {
  display: none;
}

.leftContainerImpressum {
  width: 50%;
  background-color: #d80d41;
  height: 90%;
}

.footerDatenschutz {
  display: flex;
  float: left;
  padding-top: 120px;
  padding-left: 120px;
  width: 100%;
  background-color: #d80d41;
  padding-bottom: 40px;
  font-size: 16px;
}

.footerDatenschutz .svgDivs {
  margin: 7px;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  padding: 0;
  box-shadow: none !important;
}

.footerDatenschutz .right {
  position: absolute;
  right: 200px;
  margin-top: -100px;
  font-family: bebas-neue-pro-semiexpanded, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  padding-bottom: 40px;
  text-align: right;
}

.footerDatenschutz a {
  text-decoration: none;
  color: black;
  margin-left: 10px;
}

.footerDatenschutz .right svg {
  margin-right: -50px;
}

.footerAgb {
  display: flex;
  float: left;
  padding-top: 120px;
  padding-left: 120px;
  width: 100%;
  background-color: #d80d41;
  padding-bottom: 40px;
}

.footerAgb .svgDivs {
  margin: 7px;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  padding: 0;
  box-shadow: none !important;
}

.footerAgb .right {
  position: absolute;
  right: 200px;
  margin-top: -100px;
  font-family: bebas-neue-pro-semiexpanded, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  padding-bottom: 40px;
  text-align: right;
}

.footerAgb a {
  text-decoration: none;
  color: black;
  margin-left: 10px;
}

.footerAgb .right svg {
  margin-right: -50px;
}

.footerImpressum {
  display: flex;
  float: left;
  padding-top: 120px;
  padding-left: 120px;
  width: 100%;
  background-color: #d80d41;
  padding-bottom: 40px;
}

.footerImpressum .svgDivs {
  margin: 7px;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  padding: 0;
  box-shadow: none !important;
}

.footerImpressum .right {
  position: absolute;
  right: 200px;
  margin-top: -100px;
  font-family: bebas-neue-pro-semiexpanded, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  padding-bottom: 40px;
  text-align: right;
}

.footerImpressum a {
  text-decoration: none;
  color: black;
  margin-left: 10px;
}

.footerImpressum .right svg {
  margin-right: -50px;
}

@media (max-width: 1400px) {
  .imp,
  .agb,
  .datenText {
    padding-left: 60px;
  }
}

@media (max-width: 1020px) {
  .imp,
  .datenText {
    padding-left: 68px;
    padding-top: 40px;
    width: 95%;
  }

  .agb {
    width: 95%;
    padding-left: 68px;
  }
}

@media (max-width: 470px) {
  .imp,
  .agb,
  .datenText {
    padding-left: 30px;
  }

  .agb {
    width: 100%;
    padding-right: 50px;
  }
}
