.CookieConsent {
  display: block !important;
}

.CookieConsent div:nth-child(2) {
  width: 100%;
  display: flex;
  align-items: center;
}

.redLogoUnternehmen {
  position: fixed;
  bottom: 90px;
}

.redLogoUnternehmenFixed {
  display: none;
}

.right a:hover {
  color: #d80d41;
}
